@import url("https://fonts.googleapis.com/css?family=Yantramanav:100,300");

body {
  margin: 0;
  font-family: "Yantramanav", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.imageHeaderContainer {
  position: relative;

  width: 100%;
}
.headerImage {
  vertical-align: middle;
  width: 100%;
  height: 60rem;
  background: all 4.2s ease-in-out;
  filter: brightness(50%);
}

.headerImage-contact {
  vertical-align: middle;
  width: 100%;
  height: 30rem;
  background: all 4.2s ease-in-out;
  filter: brightness(50%);
}
.HotelImage {
  /* vertical-align: middle; */
  width: 100%;
  /* position: relative; */
  /* bottom: 20px; */
  height: 500px;
  margin-bottom: 25px;
  /* border-radius: 1em; */
}

.headerText {
  position: absolute;
  top: 100px;
  color: white;
  text-align: center;
  /* width: 50%; */
  /* align-content: center; */
  /* align-items: center; */
  /* margin-left: 250px; */
  top: 520px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* height: 222px; */
}

.headerText-contact {
  position: absolute;
  top: 100px;
  color: white;
  text-align: center;
  /* width: 50%; */
  /* align-content: center; */
  /* align-items: center; */
  /* margin-left: 250px; */
  top: 360px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* height: 222px; */
}

.headerForm {
  width: 100%;
  padding-bottom: 15px;
  margin-top: 100px;
  background: rgb(174, 174, 174);
  opacity: 0.8;
  color: black;
  font-weight: 800;
}

.icon-empty {
  margin: 10px;
  font-size: 20px;
  color: red;
}

.aboutContent {
  text-align: center;
  background-color: rgb(249, 239, 239);
  /* margin-bottom: 50px; */
  padding-bottom: 25px;
}
/* 
.aboutHotel {
  background-color: rgb(234, 231, 231);
} */

.hotelCard {
  /* position: relative; */
  /* bottom: 10px; */
  border-radius: 1em;
  color: white;
  /* height: 25rem; */
  /* height: 500px; */
  margin-bottom: 25px;
  padding-bottom: 25px;
  background-color: rgb(95, 118, 140);
}

.footer {
  background-color: #50688a;
  color: white;
  position: relative;
  /* padding-bottom: 110px; */
}
.footerLayOne {
  position: relative;

  top: 50px;
}
.footerLayOne p {
  font-size: 11px;
  margin-bottom: 30px;
  position: relative;
}
.footerLayTwo {
  background-color: rgb(17, 38, 58);
  position: relative;
  height: 4rem;
}

.maps {
  display: block;
}

.footerLogo {
  background-color: white;
  margin-bottom: 25px;
}

.socialCard {
  position: relative;
  top: 20px;
  font-size: 11px;
}

.social {
  width: 100%;
  text-align: center;
}

.tab {
  background-color: #50688a;
  color: white;
}

.nav-link:focus,
.nav-link:hover {
  color: white;
}

.nav-link {
  color: white;
}

.hotel {
  width: 100%;
}
.aboutHotel {
  width: 100%;
  margin-top: 40px;
}

.body-card {
  margin-top: 25px;
}

.hotel-class {
  position: relative;
  top: 30px;
}

.card-container-booking {
  background-color: #fff;
  width: 100%;
  max-height: auto;
  margin: auto;
  height: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.card-container-booking-form {
  background-color: #607490;
  color: white;
  width: 100%;
  max-height: auto;
  margin: auto;
  height: auto;
  margin-bottom: 50px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

/* .image-conatiner {
  width: 50%;
}

.content-conatiner {
  width: 50%;
} */
.comment {
  position: relative;
  bottom: 15px;
}

.booking-container {
  position: relative;
  background-image: linear-gradient(to right, #151515, #637895);
  height: 350px;
  margin-bottom: 50px;
}

.booking-content {
  color: rgb(226, 221, 221);
  text-align: center;
  position: relative;
}
.booking-content h5 {
  margin-bottom: 25px;
}
.booking-content p {
  font-size: 15px;
}

.address-icon {
  color: gray;
}

.image-booking-main {
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 294px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.image-booking {
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 147px;
}
.price {
  font-weight: 600;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.navbar {
  font-weight: 700;
}

@media screen and (min-width: 480px) {
  /* For Tablets */
  .booking-container {
    background-image: linear-gradient(to right, #151515, #637895);
    height: 350px;
    padding: 100px;
  }
}

@media screen and (min-width: 600px) {
  /* For Tablets */
}

@media screen and (min-width: 768px) {
  /* For Laptops */
  .hotelCard {
    position: relative;
    border-radius: 1em;
    color: white;
    margin-top: 8rem;
    right: 4rem;
    padding-bottom: 25px;
    background-color: rgb(95, 118, 140);
  }
}

.s-details {
  font-size: 23px;
  background-image: linear-gradient(to right, #151515, #637895);
  color: white;
}
.s-card {
  width: 100%;
  margin-left: 0px;
  color: black;
  font-weight: 800;
}

@media screen and (min-width: 992px) {
  /* For Large Laptops */
}
a {
  color: linear-gradient(to right, #151515, #637895);
  text-decoration: none;
}

.link a {
  color: white;
}

a:hover {
  color: #00a0c6;
  text-decoration: none;
  cursor: pointer;
}

.social-icons li {
  display: inline;
  margin: 5px;
}
.social-icons li i {
  font-size: 15px;
}
